import React from 'react';
import s from './styles.module.scss';
import cn from 'classnames';
import { Title } from '../../../ui/Title';
import { useTranslation } from 'react-i18next';
import { CrossSaleBlocksProps } from './types';
import CrossSaleBlock from './CrossSaleBlock';

const CrossSaleBlocks: React.FC<CrossSaleBlocksProps> = ({ cross_sales, choosen_cross_sales, changeCrossSales }) => {
  const { t } = useTranslation();

  const handleCrossSale = (id: number) => {
    const isItemSelected = choosen_cross_sales.includes(id);
    isItemSelected
      ? changeCrossSales([...choosen_cross_sales.filter((item) => item !== id)])
      : changeCrossSales([...choosen_cross_sales, id]);
  };

  return (
    <div className={s.body}>
      <Title title={t('scriptPage.addCrossSale')} variant="h3" className={s.body__title} />
      <div className={s.blocks}>
        {cross_sales.map((block) => {
          return (
            <CrossSaleBlock
              key={block.id}
              cross_sale={block}
              onClick={handleCrossSale}
              className={cn(s.edit, { [s.selected]: choosen_cross_sales.includes(block.id) })} />
          );
        })}
      </div>
    </div>
  );
};

export default CrossSaleBlocks;
