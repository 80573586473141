import React, { useEffect, useRef, useState } from 'react';
import {
  ScriptBlock,
  InfoModal,
  PhotoModal,
  RegulationsModal,
  AdditionalFields
} from '../components/modules/ScriptPage';
import { Title } from '../components/ui/Title';
import { HorizontalArrowIcon } from '../components/ui/Icons';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  useGetAdditionalFieldsQuery,
  useGetObjectionsQuery,
  useGetProjectQuery,
  useGetScriptCrossSalesQuery,
  useGetScriptQuery,
  useGetUpdatesQuery,
  useViewFieldUpdateMutation,
  useViewScriptUpdateMutation,
  useViewRegulationsUpdateMutation,
  useViewInfoUpdateMutation
} from '../store/api';
import { Loading } from '../components/ui/Loading';
import { ObjectionsItems } from '../components/modules/ObjectionsPage';
import { CreateButton } from '../components/ui/Buttons';
import { RoleBasedComponent } from '../components/roles';
import { Roles, Status } from '../constants';
import { Notification } from '../components/ui/Modals';
import { CrossSaleSlider } from '../components/modules/CrossSalePage';

const Script: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const project_id = Number(new URLSearchParams(location.search).get('project_id'));
  const { data: project } = useGetProjectQuery(project_id);
  const { data: script, isLoading: isLoadingOnScript } = useGetScriptQuery(Number(id));
  const { name = '', photos, videos } = script?.data || {};
  const { data: additional_fields } = useGetAdditionalFieldsQuery(script?.data.id, {
    skip: !script?.data.id
  });
  const { data: script_updates } = useGetUpdatesQuery(Number(id));
  const { data: cross_sales, isLoading: isLoadingOnCrossSales } = useGetScriptCrossSalesQuery(
    Number(id)
  );
  const { data: objections } = useGetObjectionsQuery();
  const isLoading = isLoadingOnScript || isLoadingOnCrossSales;

  const [viewScriptUpdate] = useViewScriptUpdateMutation();
  const [viewFieldUpdate] = useViewFieldUpdateMutation();
  const [viewRegulationsUpdate] = useViewRegulationsUpdateMutation();
  const [viewInfoUpdate] = useViewInfoUpdateMutation();

  const fieldsRef = useRef<Map<number, HTMLDivElement | null>>(new Map());
  const showFieldsRef = useRef<{ id: number; update_id: number }[]>([]);
  const [showFieldsIds, setShowFieldsIds] = useState<number[]>([]);
  const showRegulationsId = useRef<number | null>(null);
  const showInfoId = useRef<number | null>(null);

  const possibleCrossSales = cross_sales?.data
    .filter((item) => item.status !== Status.BLOCKED)
    .sort((a, b) => b.order_id - a.order_id);
  const [addCrossSaleNotification, setAddCrossSaleNotification] = useState(false);

  const [openInfo, setOpenInfo] = useState(false);
  const [openPhoto, setOpenPhoto] = useState(false);
  const [openRegulations, setOpenRegulations] = useState(false);

  const handleCloseInfo = () => {
    setOpenInfo(false);
  };

  const handleClosePhoto = () => {
    setOpenPhoto(false);
  };

  const handleCloseRegulations = () => {
    setOpenRegulations(false);
  };

  const handleBack = () => {
    const result = navigate(-1);
    if (result === undefined) {
      navigate(`/scripts?project_id=${project_id}`);
    }
  };

  const handleGoToObjection = () => {
    navigate('/objections');
  };

  const handleCloseAddCrossSaleNotification = () => {
    setAddCrossSaleNotification(false);
  };

  const handleSetShowFields = (id: number, update_id: number) => {
    showFieldsRef.current = [...showFieldsRef.current, { id, update_id }];
    setShowFieldsIds([...showFieldsIds, id]);
    const field = fieldsRef?.current?.get(id);
    field &&
      setTimeout(() => {
        const scrollPosition = field.getBoundingClientRect().top - 100;
        window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
      }, 200);
  };

  const handleSetShowRegulations = (project_id: number) => {
    showRegulationsId.current = project_id;
  };

  const handleSetShowInfo = (script_id: number) => {
    showInfoId.current = script_id;
  };

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (!addCrossSaleNotification) {
      timer = setTimeout(() => {
        setAddCrossSaleNotification(true);
      }, 60000);
    }

    return () => {
      timer && clearTimeout(timer);
    };
  }, [addCrossSaleNotification]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (script_updates?.data.script_changes.length) {
        viewScriptUpdate({
          id: script_updates.data.script_id,
          update_id: script_updates.data.script_update_id
        });
      }
      showFieldsRef.current.forEach((field) => {
        viewFieldUpdate({ id: field.id, update_id: field.update_id });
      });
      if (showRegulationsId.current) {
        viewRegulationsUpdate(showRegulationsId.current);
      }
      if (showInfoId.current) {
        viewInfoUpdate(showInfoId.current);
      }
    };

    return () => {
      handleBeforeUnload();
    };
  }, [script_updates?.data.script_changes]);

  if (isLoading) {
    return (
      <div className="script" id="script">
        <Loading />
      </div>
    );
  }

  if (!script) {
    return (
      <div className="script" id="script">
        <Title
          className="empty"
          title={t('scriptPage.scriptNotFound')}
          variant={'h2'}
          textAlign="center"
        />
      </div>
    );
  }

  return (
    <div className="script" id="script">
      <div className="container--big">
        <div className="script__inner">
          <div className="script__btnBack" onClick={handleBack}>
            <HorizontalArrowIcon />
            <Title
              title={name + ' / ' + project?.data.name}
              variant={'h1'}
              className="script__title"
            />
          </div>
          <div className="script__blocks">
            <div className="script__body">
              <div className="script__main">
                <ScriptBlock
                  script={script.data}
                  setShowField={handleSetShowFields}
                  setShowRegulations={handleSetShowRegulations}
                  setShowInfo={handleSetShowInfo}
                  openInfo={setOpenInfo}
                  openPhoto={setOpenPhoto}
                  openRegulations={setOpenRegulations}
                  scriptUpdates={script_updates?.data}
                  additional_fields={additional_fields?.data ? additional_fields?.data : []}
                />
              </div>
              {!!possibleCrossSales?.length && <CrossSaleSlider cross_sales={possibleCrossSales} />}
              {!!additional_fields?.data?.length && (
                <AdditionalFields
                  fieldsRef={fieldsRef}
                  additional_fields={additional_fields?.data}
                  editAdditionalField={() => null}
                  withoutFuncs
                  showFieldsIds={showFieldsIds}
                  changes={script_updates?.data.script_fields_changes}
                />
              )}
            </div>
            <div className="script__objections">
              <Title title={t('objections')} variant="h2" className="script__objections-title" />
              {!!objections?.data?.length && (
                <ObjectionsItems
                  objections={objections.data}
                  editObjection={() => null}
                  withoutFuncs
                />
              )}
              <RoleBasedComponent roles={[Roles.ADMIN, Roles.SUPERADMIN]}>
                <CreateButton onClick={handleGoToObjection} className="script__objections-btn">
                  {t('objectionPage.addObjection')}
                </CreateButton>
              </RoleBasedComponent>
            </div>
          </div>
        </div>
      </div>
      <RoleBasedComponent roles={[Roles.MANAGER]}>
        <Notification
          title={t('scriptPage.addCrossSaleNotification')}
          open={addCrossSaleNotification}
          onClose={handleCloseAddCrossSaleNotification}
          timeOutMs={10000}
          red
          withExclamationIcon
          withoutCloseIcon
        />
      </RoleBasedComponent>
      <InfoModal
        open={openInfo}
        onClose={handleCloseInfo}
        script_id={Number(id) ?? 0}
        changes={script_updates?.data.important_changes}
      />
      <PhotoModal
        open={openPhoto}
        onClose={handleClosePhoto}
        photos={photos}
        videos={videos}
        script_id={Number(id)}
      />
      <RegulationsModal
        open={openRegulations}
        onClose={handleCloseRegulations}
        project_id={project_id}
        changes={script_updates?.data.regulation_changes}
      />
    </div>
  );
};

export default Script;
